@import "../global.scss";

.navigation {
  background-color: $main-black;
  color: #f0f0f0;
  font-size: 0.9em;
  font-weight: 600;
  text-transform: uppercase;

  height: $nav-height;

  display: flex;
  align-items: center;

  .nav-inner {
    color: #f0f0f0;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    flex: 1;

    margin: auto;

    padding: 0 24px;
    background: none;
    border: none;
    cursor: pointer;
  }

  .nav-avatar {
    // max-width: $max-width;
    // width: 100%;
    color: #f0f0f0;
    font-size: 1em;
    font-weight: 600;
    text-transform: uppercase;
    text-align: left;
    flex: 5;

    margin: auto;

    padding: 0 24px;
    background: none;
    border: none;

    cursor: pointer;
  }
}
