@import "../global.scss";

$divider-width: 24px;

.home-page {
  height: 100vh;

  display: flex;
  flex-direction: column;

  .hp-body {
    background-color: $main-bg;

    display: flex;
    flex-direction: column;
    flex: 1;

    .hp-body-inner {
      width: 100%;
      max-width: $max-width;
      margin: 0 auto;

      min-height: 100%;

      padding: 36px $divider-width;

      display: flex;

      .hp-body-inner-left {
        width: 40%;
        // min-width: 300px;
        margin-right: 24px;
      }

      .hp-body-inner-right {
        flex: 1;
      }
    }
  }
}

@media only screen and (max-width: $break-width) {
  .hp-body-inner {
    flex-direction: column !important;

    .hp-body-inner-left {
      width: 100% !important;
      margin-right: 0 !important;
      margin: 0 0 $divider-width;
    }

    .hp-body-inner-right {
      flex: 1;
    }
  }
}
