$main-black: #232323;
$main-white: #f0f0f0;
$main-bg: #f0f3f9;

$control-panel-bg: white;
$design-panel-bg: white;
$font-color: #334e70;

$nav-height: 42px;
$footer-height: 42px;

$max-width: 1280px;

$break-width: 799px;
