@import "../global.scss";

@font-face {
  font-family: "NexaBold";
  src: url("../../assets//fonts//Nexa//Nexa Bold.otf");
}

@font-face {
  font-family: "Gotham";
  src: url("../../assets//fonts//Gotham//GothamMedium.ttf");
}

@font-face {
  font-family: "NunitoSans";
  src: url("../../assets//fonts//Nunito_Sans//NunitoSans-Bold.ttf");
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets//fonts//Poppins//Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Inter";
  src: url("../../assets//fonts//Inter//Inter-SemiBold.ttf");
}

.avatar-panel {
  background-color: $control-panel-bg;
  padding: 24px 12px;
  box-shadow: 0 10px 12px -5px rgb(51 78 112 / 25%);

  border-radius: 24px;

  .ap-inner {
    .ap-inner-header {
      text-transform: uppercase;
      font-size: 0.84em;
      font-weight: 300;
      color: $font-color;

      padding: 0 16px;
      margin-bottom: 8px;
    }

    .ap-inner-body {
      padding: 24px 24px;

      .avatar {
        position: relative;
        display: inline-block;
        width: 100px;

        .logo {
          display: block;
          width: 100px;
        }

        .initials {
          text-align: center;
          color: white;
          position: absolute;
          top: 55%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-family: "NexaBold";
          font-size: 40px;
        }
      }
    }

    .ap-inner-footer {
      padding: 12px 16px 8px;

      display: flex;
      flex-direction: column;
    }
  }
}

.ap-status {
  font-size: 0.8em;
  color: $font-color;
  font-weight: 300;
}

.ap-button {
  cursor: pointer;
  background-color: $main-black;
  border: none;
  color: white;

  padding: 8px 24px;

  font-size: 0.74em;
  text-transform: uppercase;

  border-radius: 24px;
}

.ap-button:hover {
  background-color: #202020;
}
.ap-button:active {
  background-color: #000000;
}

.ap-button:first-child {
  margin-right: 8px;
}
